import Wrapper from "./components/Wrapper";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Wrapper />
      </AuthProvider>
    </div>
  );
}

export default App;

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Pagination } from '@material-ui/lab'

export default function AccLogList() {
    const { GetLogbooks } = useDb()
    const logbooks = GetLogbooks()

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(logbooks.length / itemsPerPage)
    );

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Client</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>View</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        logbooks && logbooks
                        .slice((page -1) * itemsPerPage, page * itemsPerPage)
                        .map(lb => (
                            <TableRow key={lb.id}>
                                <TableCell>
                                    <Link to={`/clients/${lb.client.id}`}>
                                        {lb.client.label}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link to={`/logbook/${lb.id}`}>
                                        {moment(lb.created.toDate()).fromNow()}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link to={`/logbook/${lb.id}`}>
                                        View Logbook
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <Pagination
                count={Math.ceil(logbooks.length / itemsPerPage)}
                page={page}
                onChange={(e, val) => setPage(val)}
                defaultPage={1}
                color="primary"
                size="small"
                showFirstButton
                showLastButton
                className="fml-pagination"
            />
        </TableContainer>
    )
}

import { Box, Button, Card, CardActions, CardContent, LinearProgress, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { storage } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext';
import { CloudUpload } from '@material-ui/icons';

function LinearProgressWithLabel(props) {
    return (
    <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
        )}%`}</Typography>
        </Box>
    </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function FileUploader({ getUrl, timeline, logId }) {
    const { currentUser } = useAuth()
    const [fileSelected, setFileSelected] = useState(false);
    const [isUploading, setIsUploading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState('')
    const [file, setFile] = useState(null);
    
    const storageRef = timeline ? storage.ref('timelines').child(currentUser.uid) : storage.ref('users').child(currentUser.uid)

    const metadata = {
        customMetadata: {
            "uid": `${currentUser.uid}`,
            "logId": `${logId}`
        }
    }

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
            setFileSelected(true)
        }
    }

    const handleUploadStart = async () => {
        setIsUploading(true)
        setProgress(0)
        setError(null)
        
        const uploadTask = storageRef.child(`/${file.name}`).put(file, metadata);
        
        uploadTask.on(
            'state_changed',
            snapshot => {
                var upProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(upProgress)
            },
            error => {
                setError(error.message)
                setIsUploading(false)
                setFileSelected(false)
            },
            async () => {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    getUrl(url)
                    setProgress(100)
                    setIsUploading(false)
                    setFileSelected(false)
                    
                })
            }
        )
    }

    return (
        <Card>
            <CardContent>
                Upload File:<br/>
                
                <input type="file" onChange={handleFileChange}/>
                <LinearProgressWithLabel value={progress} />
                { error && <Typography color="secondary">{error}</Typography>}
            </CardContent>
            <CardActions className="iso-right">
                <Button disabled={!fileSelected || isUploading } onClick={handleUploadStart} startIcon={<CloudUpload />} variant="contained" color="primary">Upload</Button>
            </CardActions>
        </Card>
    )
}

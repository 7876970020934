import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AnonSignIn from '../components/auth/AnonSignIn'
import CreateLogbook from '../components/logbook-tool/CreateLogbook'
import PDFDownload from '../components/logbook-tool/PDFDownload'

export default function CustomerRoutes() {
    return (
        <div>
            <Routes>
                <Route path={'/'} element={<AnonSignIn />} />
                <Route path="/logbook/:logId" element={<CreateLogbook />} />
                <Route path="/logbook/pdf/download/" element={<PDFDownload />} />
            </Routes>
        </div>
    )
}

import { Button, Typography } from '@material-ui/core'
import { PostAdd } from '@material-ui/icons'
import React from 'react'
import AccLogList from './AccLogList'
import AddAccLogbook from './AddAccLogbook'

export default function AccountantLogBooks() {
    
    const [addOpen, setAddOpen] = React.useState(false)

    return (
        <div>
            <Typography variant="h5">Logbooks</Typography>
            <div className="top-btn-row">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PostAdd />}
                    onClick={() => setAddOpen(true)}
                >
                    Add Logbook
                </Button>
            </div>
            <div className="fml-container">
                <AccLogList />
            </div>

            <AddAccLogbook 
                open={addOpen}
                close={() => setAddOpen(false)}
            />
        </div>
    )
}

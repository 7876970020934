import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useState } from 'react'
import {useLocation} from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useDb } from '../../contexts/DatabaseContext'

export default function PDFDownload() {

  const location = useLocation()
  const { currentUser } = useAuth()
  const { getUserLogbook, createLogBookPdf } = useDb()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  let log

  console.log(currentUser)

  useEffect( async () => {
    setLoading(true)
    log = await getUserLogbook(currentUser.uid)
    console.log(log)
    const dataObj = await fetch(log).then(async res => {
      return await res.json() 
    })
    setData(dataObj)
    setLoading(false)
  }, [])


  const ref = new URLSearchParams(location.search).get('reference')


  const handlePDF = () => {
    createLogBookPdf(data)
  }

  return (
    <div>
      <Grid container alignContent='center' spacing={5}>
        <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6} style={{marginTop: 30}}>
            <Card elevation={3}>
              <CardHeader 
                title='Thank You your payment was Successful'
              />
              <CardContent>
                <center>Get Your PDF Download Here</center>
              </CardContent>
              <CardActions>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  style={{ clear: 'both' }}
                  onClick={handlePDF}
                >Download PDF</Button>
              </CardActions>
            </Card>
          </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>
    </div>
  )
}

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import { Pagination } from '@material-ui/lab'

export default function ClientList() {
    const { GetAccClients } = useDb()
    const clients = GetAccClients()

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(clients.length / itemsPerPage)
    );

    return (
        <div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Company</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            clients && clients
                            .slice((page -1) * itemsPerPage, page * itemsPerPage)
                            .map(c => (
                                <TableRow key={c.id}>
                                    <TableCell>
                                        <Link to={`/clients/${c.id}`} key={c.id}>
                                            {`${c.firstName} ${c.lastName}`}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/clients/${c.id}`} key={c.id}>
                                            {c.email}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/clients/${c.id}`} key={c.id}>
                                            {c.phone}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/clients/${c.id}`} key={c.id}>
                                            {c.company}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <Pagination
                    count={Math.ceil(clients.length / itemsPerPage)}
                    page={page}
                    onChange={(e, val) => setPage(val)}
                    defaultPage={1}
                    color="primary"
                    size="small"
                    showFirstButton
                    showLastButton
                    className="fml-pagination"
                />
            </TableContainer>
             
        </div>
    )
}

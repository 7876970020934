import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// Initialize Firebase
const app = firebase.initializeApp({
    apiKey: "AIzaSyBTapW89ycMsuFrtT-kUUwQT6x38bnzEVE",
    authDomain: "forgotmylogbook.firebaseapp.com",
    projectId: "forgotmylogbook",
    storageBucket: "forgotmylogbook.appspot.com",
    messagingSenderId: "1072142268532",
    appId: "1:1072142268532:web:7c1918d779df866ff27921"
});

export const auth = app.auth()
export const db = app.firestore()
export const fieldValue = firebase.firestore.FieldValue 
export const storage = app.storage()

export default app
import React, { useState } from 'react'
import { Card, CardContent, Button, CircularProgress } from '@material-ui/core'
import { useDb } from '../../contexts/DatabaseContext'
import { useNavigate } from 'react-router-dom';

export default function AnonSignIn() {

  const navigate = useNavigate()
  const { anonSetup } = useDb()
  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    setLoading(true)
    await anonSetup().then((doc) => {
      navigate(`logbook/${doc}`)
    })
  }

  return (
    <div style={{ background: '#0ba9ea', height: '100vh'}}>
      <center>
        <br/>
      <Card raised style={{width: 300, height: 'auto', margin: 30}}>
        <CardContent style={{padding:10}}>
            Click the button below to begin the Logbook Process
          <div style={{marginTop: 20}}>

          <center>
            {
              loading 
              ?
              <CircularProgress />
              :
              <Button
                color='primary'
                variant='contained'
                onClick={handleClick}
              >Get Started</Button>
            }
          </center>
          </div>
        </CardContent>
      </Card>
</center>
    </div>
  )
}

import moment from "moment";

export const textFieldProps = {
    variant: 'outlined',
    fullWidth: true,
    style: { marginBottom: 10 }
}

const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = 'black';
        return {
          ...styles,
          color
        }
    }
}

export const selectFieldProps = {
    menuPortalTarget: document.body,
    styles: selectStyles
}

export const charLib = [
    'a',
    'A',
    'b',
    'B',
    'c',
    'C',
    'd',
    'D',
    'e',
    'E',
    'f',
    'F',
    'g',
    'G',
    'h',
    'H',
    'i',
    'I',
    'j',
    'J',
    'k',
    'K',
    'l',
    'L',
    'm',
    'M',
    'n',
    'N',
    'o',
    'O',
    'p',
    'P',
    'q',
    'Q',
    'r',
    'R',
    's',
    'R',
    't',
    'R',
    'u',
    'U',
    'v',
    'V',
    'w',
    'W',
    'z',
    'X',
    'y',
    'Y',
    'z',
    'Z',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
]

export const publicHolidays = [
    moment('2020-01-01').toDate(),
    moment('2020-01-04').toDate(),
    moment('2020-03-21').toDate(),
    moment('2020-04-10').toDate(),
    moment('2020-04-11').toDate(),
    moment('2020-04-12').toDate(),
    moment('2020-04-13').toDate(),
    moment('2020-04-27').toDate(),
    moment('2020-05-01').toDate(),
    moment('2020-05-10').toDate(),
    moment('2020-06-16').toDate(),
    moment('2020-06-21').toDate(),
    moment('2020-07-18').toDate(),
    moment('2021-01-01').toDate(),
    moment('2021-03-21').toDate(),
    moment('2021-03-22').toDate(),
    moment('2021-04-2').toDate(),
    moment('2021-04-5').toDate(),
    moment('2021-05-1').toDate(),
    moment('2021-06-16').toDate(),
    moment('2021-08-9').toDate(),
    moment('2021-09-24').toDate(),
    moment('2021-12-25').toDate(),
    moment('2021-12-26').toDate(),
    moment('2021-12-27').toDate(),
    moment('2022-01-01').toDate(),
    moment('2022-04-21').toDate(),
    moment('2022-04-22').toDate(),
    moment('2022-05-2').toDate(),
    moment('2022-05-27').toDate(),
    moment('2022-06-1').toDate(),
    moment('2022-06-16').toDate(),
    moment('2022-09-9').toDate(),
    moment('2022-09-24').toDate(),
    moment('2022-12-01').toDate(),
    moment('2022-12-25').toDate(),
    moment('2022-12-26').toDate(),
    moment('2022-12-27').toDate(),
    moment('2023-01-01').toDate(),
    moment('2023-03-21').toDate(),
    moment('2023-04-15').toDate(),
    moment('2023-04-18').toDate(),
    moment('2023-04-27').toDate(),
    moment('2023-05-01').toDate(),
    moment('2023-06-16').toDate(),
    moment('2023-09-09').toDate(),
    moment('2023-09-24').toDate(),
    moment('2023-12-16').toDate(),
    moment('2023-12-25').toDate(),
    moment('2023-12-26').toDate()
]

export const getId = () => {
    let idArray = []
    for (let i = 0; i < 18; i++) {
      const char = charLib[Math.ceil(Math.random() * charLib.length - 1)]; 
      idArray.push(char)  
    }
    return idArray.join('')
  }

export const checkVehicleCost = (bookVal) => {
    let val
    if (bookVal <= 95000) {
        val = vehicleCost[95000]
    } else if (bookVal > 95000 && bookVal <= 190000) {
        val = vehicleCost[190000]
    } else if (190000 && bookVal <= 285000) {
        val = vehicleCost[285000]
    } else if (bookVal > 285000 && bookVal <= 380000) {
        val = vehicleCost[380000]
    } else if (bookVal > 380000 && bookVal <= 475000) {
        val = vehicleCost[475000]
    } else if (bookVal > 475000 && bookVal <= 570000) {
        val = vehicleCost[570000]
    } else if (bookVal > 570000 && bookVal <= 665000) {
        val = vehicleCost[665000]
    } else {
        val = 'Error'
    }
    return val
}

const vehicleCost = {
    95000: {
        fixedCost: 29504,
        fuelCost: 104.1,
        maintenanceCost: 38.6
    },
    190000: {
        fixedCost: 52226,
        fuelCost: 116.2,
        maintenanceCost: 48.3
    },
    285000: {
        fixedCost: 75039,
        fuelCost: 126.3,
        maintenanceCost: 53.2
    },
    380000: {
        fixedCost: 94871,
        fuelCost: 135.8,
        maintenanceCost: 58.1
    },
    475000: {
        fixedCost: 114781,
        fuelCost: 145.3,
        maintenanceCost: 68.3
    },
    570000: {
        fixedCost: 156746,
        fuelCost: 166.7,
        maintenanceCost: 80.2
    },
    665000: {
        fixedCost: 156711,
        fuelCost: 172.4,
        maintenanceCost: 99.6
    }
}
import React, { useContext, useEffect, useState } from 'react'
import { auth, db } from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    async function createAccount(data) {
        return await auth.createUserWithEmailAndPassword(data.email, data.password).then(async user => {
            let userData = {};
            if(data.accountant) {
                userData = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    firmName: data.firmName,
                    accountant: true
                }
            }
            else {
                userData = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email
                }
            }
            await db.collection('users').doc(user.user.uid).set({
                ...userData
            })
        })
    }

    function signIn(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logOut() {
        return auth.signOut()
    }

    function sendForgotPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    async function getUserDoc(uid) {
        return await db.collection('users').doc(uid)
        .get().then(doc => {
            return doc.data()
        })
    }

    async function anonSignIn() {
        return auth.signInAnonymously()
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            if(user) {
                const userDoc = await getUserDoc(user.uid)
                setCurrentUser({ ...user, ...userDoc })
            }
            setLoading(false)
        })
        return unsubscribe
    }, [])

    const value = {
        currentUser,
        createAccount,
        logOut,
        sendForgotPassword,
        signIn,
        anonSignIn
    }
    
    return (
        <AuthContext.Provider value={value}>
            { !loading && children }
        </AuthContext.Provider>
    )
}

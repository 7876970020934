import { Button, Card, CardContent, Container, CssBaseline, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      alignItems: 'center',
      marginBottom: theme.spacing(1)
    },
    subCard: {
        alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp() {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Card className={classes.paper}>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card className={classes.subCard}>
                                <CardContent>
                                    <Typography>I am an Accountant</Typography>
                                    <Link to="/signup-accountant">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                        >
                                            Sign Up
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card className={classes.subCard}>
                                <CardContent>
                                    <Typography>I am an Individual</Typography>
                                    <Link to="/signup-individual">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                        >
                                            Sign Up
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Grid container justifyContent="flex-end">
                <Grid item>
                <Link to="/signin" variant="body2">
                    Already have an account? Sign in
                </Link>
                </Grid>
            </Grid>
        </Container>
    )
}

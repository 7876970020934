import React from 'react'
import { Routes, Route } from 'react-router-dom'
import AnonSignIn from '../components/auth/AnonSignIn'
import ForgotPassword from '../components/auth/ForgotPassword'
import SignIn from '../components/auth/SignIn'
import SignUp from '../components/auth/SignUp'
import SignUpAccountant from '../components/auth/SignUpAccountant'
import SignUpCustomer from '../components/auth/SignUpCustomer'
import Home from '../components/home/Home'

export default function PublicRoutes() {
    return (
        <Routes>
            <Route path="/" element={<AnonSignIn />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signup-accountant" element={<SignUpAccountant />} />
            <Route path="/signup-individual" element={<SignUpCustomer />} />
            <Route path="/forgot" element={<ForgotPassword />} />
        </Routes>
    )
}

import { Button, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

export default function ClientDetail() {
    const { clientId } = useParams()
    const navigate = useNavigate()

    return (
        <div>
            <Typography variant="h5">Client Detail</Typography>
            <div className="top-btn-row">
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowBack />}
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </div>        
        </div>
    )
}

import { Button, Typography } from '@material-ui/core'
import { useAuth } from '../../../contexts/AuthContext'
import React from 'react'

export default function AccYourAccount() {

    const { logOut } = useAuth()

    const handleLogOut = async () => {
        await logOut()
    }

    return (
        <div>
            <Typography variant="h5">Your Account</Typography>
            <Button onClick={handleLogOut}>LogOut</Button>
        </div>
    )
}

import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import AccountantClients from '../components/accountant/clients/AccountantClients'
import ClientDetail from '../components/accountant/clients/ClientDetail'
import AccountantHome from '../components/accountant/home/AccountantHome'
import AccountantLogBooks from '../components/accountant/logbooks/AccountantLogBooks'
import AccYourAccount from '../components/accountant/your-account/AccYourAccount'
import CreateLogbook from '../components/logbook-tool/CreateLogbook'
import UploadTimeline from '../components/logbook-tool/UploadTimeline'

export default function AccountantRoutes() {
    return (
        <Routes>
            <Route path="/signup-accountant" element={<Navigate to="/" replace />} />
            <Route path="/signin" element={<Navigate to="/" replace />} />
            <Route exact path="/" element={<AccountantHome />} />
            <Route exact path="/clients" element={<AccountantClients />} />
            <Route path="/clients/:clientId" element={<ClientDetail />} />
            <Route exact path="/logbooks" element={<AccountantLogBooks />} />
            <Route path="/account" element={<AccYourAccount />} />
            <Route path="/upload" element={<UploadTimeline />} />
            <Route path="/logbook/:logId" element={<CreateLogbook />} />
        </Routes>
    )
}

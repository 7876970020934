import React from 'react'
import FileUploader from './FileUploader'

export default function UploadTimeline({ logId, sendUrl }) {

    const getUrl = (u) => {
        sendUrl(u)
    }

    return (
        <div>
            <FileUploader 
                getUrl={getUrl}
                timeline={true}
                logId={logId}
            />
        </div>
    )
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import Select from 'react-select'
import { selectFieldProps } from '../../shared/constants'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main,
        color: '#fff'
    }
}))

export default function AddClient({ open, close }) {
    const classes = useStyles()
    const navigate = useNavigate()
    const { GetAccClients, createAccLogbook } = useDb()
    const clients = GetAccClients()

    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {}
    })

    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }

    const handleSelect = (v,e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.name]: v.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            const docId = await createAccLogbook(state.data.client)
            navigate(`/logbook/${docId}`)
            handleClose()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
            <DialogTitle
                className={classes.header}
            >
                Add Logbook
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Select Client
                </Typography>
                <Select
                    {...selectFieldProps}
                    placeholder="Select Client ..."
                    options={clients}
                    onChange={handleSelect}
                    name="client"
                />
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleClose}
                    disabled={state.loading}
                    size="small"
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={state.loading || !state.data.client}
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </DialogActions>

        </Dialog>
    )
}

import { createTheme, ThemeProvider } from '@material-ui/core'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { DatabaseProvider } from '../contexts/DatabaseContext'
import CustomerRoutes from '../routes/CustomerRoutes'
import PublicRoutes from '../routes/PublicRoutes'
import AccountantDashboard from './accountant/AccountantDashboard'

export default function Wrapper() {
    const { currentUser } = useAuth()

    const theme = createTheme({
        typography: {
            button: {
                textTransform: 'none'
            }
        }
    })

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <DatabaseProvider currentUser={currentUser}>
                    {
                        currentUser
                        ?
                    
                        currentUser.accountant
                        ?
                        <AccountantDashboard />
                        :
                        <CustomerRoutes />
                    
                        :
                        <PublicRoutes />
                    }
                </DatabaseProvider>
            </ThemeProvider>
        </BrowserRouter>      
    )
}

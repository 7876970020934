import { Typography } from '@material-ui/core'
import React from 'react'

export default function AccountantHome() {
    return (
        <div>
            <Typography variant="h5">Home</Typography>
        </div>
    )
}

import { Button, Typography } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import React, { useState } from 'react'
import AddClient from './AddClient'
import ClientList from './ClientList'


export default function AccountantClients() {

    const [addOpen, setAddOpen] = useState(false)

    return (
        <div>
            <Typography variant="h5">Clients</Typography>
            <div className="top-btn-row">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PersonAdd />}
                    onClick={() => setAddOpen(true)}
                >
                    Add Client
                </Button>
            </div>
            <div className="fml-container">
                <ClientList />
            </div>
            <AddClient 
                open={addOpen}
                close={() => setAddOpen(false)}
            />
        </div>
    )
}

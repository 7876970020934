import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { textFieldProps } from '../../shared/constants'

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.palette.primary.main,
        color: '#fff'
    }
}))

export default function AddClient({ open, close }) {
    const classes = useStyles()
    const { createClient } = useDb()

    const [state, setState] = React.useState({
        loading: false,
        error: null,
        data: {}
    })

    const handleClose = () => {
        setState({
            loading: false,
            error: null,
            data: {}
        })
        close()
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setState({
            ...state,
            loading: true,
            error: null
        })
        try {
            await createClient(state.data)
            handleClose()
        }
        catch(err) {
            setState({
                ...state,
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
            <DialogTitle
                className={classes.header}
            >
                Add Client
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="First Name"
                        required
                        onChange={handleChange}
                        name="firstName"
                        {...textFieldProps}
                    />
                    <TextField
                        label="Last Name"
                        required
                        onChange={handleChange}
                        name="lastName"
                        {...textFieldProps}
                    />
                    <TextField
                        label="Email"
                        required
                        onChange={handleChange}
                        name="email"
                        {...textFieldProps}
                    />
                    <TextField
                        label="Phone"
                        onChange={handleChange}
                        name="phone"
                        {...textFieldProps}
                    />
                    <TextField
                        label="Company"
                        onChange={handleChange}
                        name="company"
                        {...textFieldProps}
                    />
                    {
                        state.error && <Typography color="secondary">{state.error}</Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={handleClose}
                        disabled={state.loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={state.loading}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

import jsPDF from 'jspdf'
import React, { useContext, useState, useEffect } from 'react'
import { db, fieldValue, storage } from '../firebase'
import { useAuth } from './AuthContext'
import 'jspdf-autotable'
import moment from 'moment'

const DatabaseContext = React.createContext()

export function useDb() {
    return useContext(DatabaseContext)
}

export function DatabaseProvider({ children, currentUser }) {

    const { anonSignIn } = useAuth()

    async function anonSetup() {
        const user = await anonSignIn()

        return await db.collection('logbooks')
        .add({
            owner: user.user.uid,
            created: new Date(),
            client: {
                id: user.user.uid,
                label: user.user.uid
            },
            lastUpdated: new Date(),
            step: 0
        }).then(doc => {
            return doc.id
        })
    }
    
    function createClient(data) {
        return db.collection('acc_clients')
        .add({
            ...data,
            owner: currentUser.uid,
            created: new Date()
        })
    }

    function GetAccClients() {
        const [clients, setClients] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('acc_clients')
            .where('owner', '==', currentUser.uid)
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    label: `${doc.data().firstName} ${doc.data().lastName} (${doc.data().email})`,
                    value: { id: doc.id, label: `${doc.data().firstName} ${doc.data().lastName}`}
                }))
                setClients(data)
            })
            return unsubscribe
        }, [])
        return clients
    }

    function GetLogbooks() {
        const [logbooks, setLogbooks] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('logbooks')
            .where('owner', '==', currentUser.uid)
            .orderBy('created', 'desc')
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setLogbooks(data)
            })
            return unsubscribe
        }, [])
        return logbooks
    }

    function GetLogbookData(logId) {
        const [logbook, setLogbook] = useState([])
        useEffect(() => {
            const unsubscribe = db
            .collection('logbook_data')
            .where('uid', '==', currentUser.uid)
            .where('logId', '==', logId)
            .orderBy('timestamp', 'desc')
            .onSnapshot((snapshot) => {
                const data = !snapshot.empty ? {
                    ...snapshot.docs[0].data(),
                    id: snapshot.docs[0].id
                } : null
                setLogbook(data)
            })
            return unsubscribe
        }, [logId])
        return logbook
    }

    async function getLogbookDataSync(logId) {
        return await db
        .collection('logbook_data')
        .where('uid', '==', currentUser.uid)
        .where('logId', '==', logId)
        .orderBy('timestamp', 'desc')
        .get().then(snapshot => {
            const data = !snapshot.empty ? {
                ...snapshot.docs[0].data(),
                id: snapshot.docs[0].id
            } : null
            return data
        })
    }

    async function createAccLogbook(client) {
        return await db.collection('logbooks')
        .add({
            owner: currentUser.uid,
            created: new Date(),
            client,
            lastUpdated: new Date(),
            step: 0
        }).then(doc => {
            return doc.id
        })
    }

    async function updateLogbook(logId, data) {
        return await db.collection('logbooks')
        .doc(logId)
        .update({
            ...data,
            lastUpdated: new Date()
        })
    }

    async function getLogbook(logId) {
        return await db.collection('logbooks')
        .doc(logId)
        .get().then(doc => {
            return {
                ...doc.data(),
                id: doc.id
            }
        })
    }

    async function uploadLogbookChanges(logbookId, data) {
        // upload the json to firebase storage
        const fileName = logbookId
        const storageRef = storage.ref('json_logbooks').child(fileName)
        const jsonString = JSON.stringify(data)
        const blob = new Blob([jsonString], { type: 'application/json'})
        console.log(blob)
        const uploadTask = storageRef.put(blob)
        uploadTask.on(
            'state_changed',
            snapshot => {
                var upProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload Progress: ', upProgress)
            },
            error => {
                console.log('Error During JSON Upload')
            },
            () => {
                console.log(logbookId)
                uploadTask.snapshot.ref.getDownloadURL().then(async url => {
                    await db.collection('logbooks').doc(logbookId)
                    .update({
                        url: url
                    })
                })
                console.log('success')
            }
        )
    }

    async function getUserLogbook(userId) {
        const logData = await db.collection('logbooks')
        .where('owner', '==', userId)
        .get()
        .then(docs => {
            return docs.docs[0].data().url
        })

        return logData
    }

    // --------------------------- PDF Functions --------------------------- //

    async function createLogBookPdf(logbook) {
        
        let totalKmTotal = 0
        let fuelCostTotal = 0
        let maintenanceCostTotal = 0
        for (let i = 0; i < logbook.length; i++) {
            const log = logbook[i];
            if (log.business) {
                fuelCostTotal = fuelCostTotal + parseFloat(log.fuelCost)
                maintenanceCostTotal = maintenanceCostTotal + parseFloat(log.maintenanceCost)
                totalKmTotal = totalKmTotal + parseInt(log.totalKm)
            }
        }
        console.log(fuelCostTotal.toFixed(2), maintenanceCostTotal.toFixed(2), totalKmTotal)

        const pdf = new jsPDF({
            orientation: "landscape",
        })

        let logbookArray = []

        logbook.map(log => {
            if (log.business) {
                logbookArray.push([`${moment(log.timestamp).format('DD MMM YYYY')}`, `${log.openingKm} Km`, `${log.closingKm} Km`, `${log.totalKm} Km`, `${log.from}`, `${log.to}`, `R${log.fuelCost}`, `R${log.maintenanceCost}`])
            }
        })

        pdf.autoTable({
            head: [['Date', 'Opening Km', 'Closing Km', 'Business Km', 'From', 'To', 'Actual Fuel & Oil Cost', 'Actual Repairs & Maintenance Costs']],
            body: logbookArray,
            margin: { top: 10 }
        })

        pdf.autoTable({
            head: [['Totals', 'Total Business Km', 'Fuel & Oil Cost Total', 'Maintenance Cost Total']],
            body: [['',`${totalKmTotal} Km`, `R${fuelCostTotal.toFixed(2)}`, `R${maintenanceCostTotal.toFixed(2)}`]],
            margin: { top: 10 }
        })

        pdf.save('Logbook.pdf')
    }

    const value = {
        anonSetup,
        createClient,
        GetAccClients,
        createAccLogbook,
        GetLogbookData,
        GetLogbooks,
        updateLogbook,
        getLogbook,
        getLogbookDataSync,
        uploadLogbookChanges,
        getUserLogbook,
        createLogBookPdf
    }

    return (
        <DatabaseContext.Provider value={value}>
            { children }
        </DatabaseContext.Provider>
    )
}

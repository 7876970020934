import { Button, Card, CardActions, CardContent, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, Switch } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { charLib, checkVehicleCost, getId, publicHolidays } from '../shared/constants'
import { DataGrid, gridVisibleSortedRowIdsSelector } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDb } from '../../contexts/DatabaseContext';
import { Delete, Refresh } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    '& .working-trip': {
      backgroundColor: '#9ad086',
    },
    '& .non-working-trip': {
      backgroundColor: '#f7d97f',
    },
  },
});

export default function RLogBookTable({ data, odo, logWeekends, startHours, businessHoursOnly, endHours, logPublicHolidays, logbook }) {
  
  const {uploadLogbookChanges, createLogBookPdf} = useDb()
  const [loading, setLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const [row, setRow] = useState([])
  const [updated, setUpdated] = useState(true)
  const [bKm, setBKm] = useState(0)
  const [tKm, setTKm] = useState(0)
  const [tKmP, setTKmP] = useState(0)
  const [updateCalc, setUpdateCalc] = useState(0)
  const [open, setOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [firstLoad, setFirstLoad] = useState(0)
  const classes = useStyles()

  let vehicleCostSettings
  let filteredRow = []
  let logs = []
  let finalData = []
  
  const getYear = (year) => {
    const fileName = `json${year}`
    let months
    for (let i = 0; i < data[fileName].length; i++) {
      months = data[fileName][i];
      logs.push(months)
    }
  }

  const reworkFiles = async () => {
    let finalArray = []
    getYear(2020)
    getYear(2021)
    for (let i = 0; i < logs.length; i++) {
      const log = logs[i];
      const url = log.url[0]
      const logData = await fetch(url).then(res => {
        return res.json()
      })
      const finalData = {
        ...logData
      }
      finalArray.push(finalData)
    }
    return finalArray
  }

  const checkIfBusiness = (row) => {
    // take row and check if weekends, business, business hours, etc.
    let result
    if (typeof row.timestamp === 'string') {
      if (moment(row.timestamp).toDate().getDay() > 0 && moment(row.timestamp).toDate().getDay() < 6) {
        result = true
      }
      if (logWeekends) {
        if(moment(row.timestamp).toDate().getDay() === 0 && moment(row.timestamp).toDate().getDay() === 6) {
          result = true
        } else {
          result = false
        }
      }
      if (businessHoursOnly) {
        if (moment(row.timestamp).toDate().getHours() > startHours.toDate().getHours() && moment(row.timestamp).toDate().getHours() < endHours.toDate().getHours()) {
          result = true
        }
      }
      if (!logPublicHolidays) {
        for (let i = 0; i < publicHolidays.length; i++) {
          const ph = publicHolidays[i];
          if (moment(row.timestamp).toDate().getFullYear() === ph.getFullYear() && moment(row.timestamp).toDate().getMonth() === ph.getMonth() && moment(row.timestamp).toDate().getDate() === ph.getDate()) {
            result = false
          }
        }
      }
    } else {
      if (row.timestamp.getDay() > 0 && row.timestamp.getDay() < 6) {
        result = true
      }
      if (logWeekends) {
        if(row.timestamp.getDay() === 0 && row.timestamp.getDay() === 6) {
          result = true
        } else {
          result = false
        }
      }
      if (businessHoursOnly) {
        if (row.timestamp.getHours() > startHours.toDate().getHours() && row.timestamp.getHours() < endHours.toDate().getHours()) {
          result = true
        }
      }
      if (!logPublicHolidays) {
        for (let i = 0; i < publicHolidays.length; i++) {
          const ph = publicHolidays[i];
          if (row.timestamp.getFullYear() === ph.getFullYear() && row.timestamp.getMonth() === ph.getMonth() && row.timestamp.getDate() === ph.getDate()) {
            result = false
          }
        }
      }
    }
    return result
  }

  useEffect( async () => {
    setLoading(true)
    
    vehicleCostSettings = await checkVehicleCost(parseInt(logbook.book_value))

    let tripArray = []
    let logData = []
    if (logbook.url === undefined) {
      finalData = await reworkFiles()
      
      let startingDistance = 0;
      let overAllDistance = 0
      
      for (let i = 0; i < finalData.length; i++) {
        const logData = finalData[i];
        logData.timelineObjects.map((entry, index) => {                   
          if('activitySegment' in entry) {
            const log = entry.activitySegment
            if (log.distance !== undefined){
              overAllDistance = (overAllDistance + log.distance / 1000 ) 
            }
          }
        })
      }
      
      startingDistance = (odo - overAllDistance)
      let lastPlace = ''
      
      let tripEntry = {}
      
      for (let i = 0; i < finalData.length; i++) {
        const log = finalData[i];
        log.timelineObjects.map((entry, index) => { 
          
          if('activitySegment' in entry) {
            const log = entry.activitySegment
            if (log.activityType === 'IN_PASSENGER_VEHICLE') {
              console.log(log)
              const startDate = new Date(log.duration.startTimestamp)
              const trip = log.distance === undefined ? 0 : log.distance / 1000
              const openingKm = startingDistance 
              startingDistance = startingDistance + trip
              const closingKm = openingKm + trip
              tripEntry.date = moment(startDate).format('DD/MM/YYYY')
              tripEntry.timestamp = startDate
              tripEntry.openingKm = openingKm.toFixed(0) 
              tripEntry.closingKm = closingKm.toFixed(0)
              tripEntry.totalKm = trip.toFixed(0)
              tripEntry.id = getId()
            }
          }
          else if('placeVisit' in entry) {
            const log = entry.placeVisit
            const newPlace = log.location.address
            tripEntry.from = lastPlace
            tripEntry.to = newPlace
            tripEntry.date && tripArray.push(tripEntry)
            tripEntry = {}
            lastPlace = log.location.address
          }
        })
        logData = tripArray
      }
    } else {
      logData = await fetch(logbook.url).then(res => {
        return res.json() 
      })
    }
    const finalArray = await logData.map(d => ({
      id: d.id,
      timestamp: typeof d.timestamp === 'string' ? moment(d.timestamp).toDate() : d.timestamp,
      openingKm: d.openingKm,
      closingKm: d.closingKm,
      totalKm: d.totalKm, 
      from: d.from,
      to: d.to,
      business: d.business == undefined || d.business == null ? checkIfBusiness(d) : d.business,
      fuelCost: (vehicleCostSettings.fuelCost * parseInt(d.totalKm) / 100).toFixed(2),
      maintenanceCost: (vehicleCostSettings.maintenanceCost * parseInt(d.totalKm) / 100).toFixed(2)
    }))

    console.log(finalArray[0].fuelCost)
    if (finalArray[0].fuelCost === 'NaN'){
      window.location.reload()
    }
    
    setRow(await finalArray)
    setUpdated(true)
    setUpdateCalc(1)
    setLoading(false)
  }, [])  
  
  useEffect(() => {
    let totalBusinessKm = 0
    let totalKm = 0
    const data = filteredRow.length == 0 ? row : filteredRow
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      totalKm = totalKm + parseInt(d.totalKm)
      if (d.business) {
        totalBusinessKm = totalBusinessKm + parseInt(d.totalKm)
      }
    }
    setBKm(totalBusinessKm)
    setTKm(totalKm)
    setTKmP((totalBusinessKm/totalKm * 100).toFixed(2))
  }, [updateCalc, filteredRow])
  
  const handeleChange = (bool, id) => {
    setUpdateCalc(updateCalc + 1)
    setUpdated(true)
    row[row.findIndex(row => row.id === id)].business = bool
    setRow(row)
  }

  const handleSave = async () => {
    setSaveLoading(true)
    await uploadLogbookChanges(logbook.id, row)
    setUpdated(false)
    setSaveLoading(false)
  }

  const handleDelete = () => {
    let newRow = row.filter(r => r.id !== deleteId)
    setRow(newRow)
    setUpdateCalc(updateCalc + 1)
    setOpen(false)
  }

  const handlePDF = () => {
    const data = filteredRow.length == 0 ? row : filteredRow
    createLogBookPdf(data)
  }

  const handlePayment = () => {
    console.log(logbook.url)
  }

  const handleFilter = async (filteredItems) => {
    let filteredObj = []
    if (filteredItems !== undefined || filteredItems !== null)
    for (let i = 0; i < filteredItems.length; i++) {
      const item = filteredItems[i]
      for (let j = 0; j < row.length; j++) {
        const r = row[j];
        if (r.id === item){
          filteredObj.push(r)
        }
      }
    }
    filteredRow = filteredObj
  }

  const columns = [
    {field: 'timestamp', headerName: 'Date', width: 120, type: 'date'},
    {field: 'openingKm', headerName: 'Open Km', width: 140},
    {field: 'closingKm', headerName: 'Close Km', width: 140},
    {field: 'totalKm', headerName: 'Total Km', width: 140},
    {field: 'from', headerName: 'From', width: 320},
    {field: 'to', headerName: 'To', width: 320},
    {field: 'fuelCost', headerName: 'Fuel Cost', width: 140},
    {field: 'maintenanceCost', headerName: 'Maintenance Cost', width: 200},
    {
      field: 'isBusinessTrip',
      headerName: 'Business Trip', 
      width: 180,
      renderCell: (params) => {
        return(
          <Checkbox 
            defaultChecked={params.row.business}
            color='primary'
            onChange={(e, checked) => handeleChange(checked, params.row.id)}
          />
        )
      }
    },
    {
      field: 'delete',
      headerName: 'Delete', 
      width: 150,
      renderCell: (params) => {
        return(
          <IconButton 
            onClick={() => {
              setOpen(true)
              setDeleteId(params.row.id)
            }}
          ><Delete /></IconButton>
        )
      }
    }
  ]

  return (
    <div>
      {
        loading
        ?
        <CircularProgress />
        :
        <>
            <Card>
              <CardContent>
                <Grid container spacing={5} direction='row' justifyContent='center' alignItems='center' >
                  <Grid item xs={3} ><p>Business KM: {bKm}</p></Grid>
                  <Grid item xs={3} ><p>Total KM: {tKm}</p></Grid>
                  <Grid item xs={3} ><p style={{color: tKmP > 80 ? 'green' : 'red'}}>Business KM(%): {tKmP}</p></Grid>
                  <Grid item xs={3} ><p><IconButton onClick={() => setUpdateCalc(updateCalc + 1)}><Refresh /></IconButton></p></Grid>
                </Grid>
                <div className={classes.root} style={{ height:'700px', width: '100%' }}>
                  <DataGrid
                    disableSelectionOnClick
                    onStateChange={val => {
                      if (val.state.visibleRows.visibleRows !== undefined) {
                        handleFilter(val.state.visibleRows.visibleRows)
                      }
                    }}
                    columns={columns}
                    rows={row && row}
                    getRowClassName={(params) => {
                      let result
                      if (params.row.business) {
                        result = 'working-trip'
                      } else {
                        result = 'non-working-trip'
                      }
                      return result
                    }}
                  />
                </div>
              </CardContent>
            <CardActions>
              {
                saveLoading
                ?
                <CircularProgress />
                :
                <div>
              {
                updated 
                ?
                <Button
                  variant="contained"
                  color="primary"
                  style={{ clear: 'both' }}
                  onClick={handleSave}
                >Save</Button>
                :
                null
              }
              {/* <Button
                  variant="contained"
                  color="primary"
                  style={{ clear: 'both' }}
                  onClick={handlePDF}
                >Test PDF</Button> */}
              {
                updated
                ?
                null
                :
                <Button
                  variant="contained"
                  color="primary"
                  target={'_blank'}
                  href="https://paystack.com/pay/logbookpdf_test_1642591056614_test"
                  onClick={handlePayment}
                >
                  Continue To Payment
                </Button>
              }
              </div>
              }
            </CardActions>
          </Card>
        </>
      }
      <Dialog maxWidth="sm" fullWidth open={open}>
        <DialogContent>
          Are you sure? 
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={() => setOpen(false)}>Cancel</Button>
          <Button color='primary' onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
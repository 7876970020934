import { Avatar, Button, Card, CardActions, CardHeader, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, FormLabel, Grid, makeStyles, Step, StepContent, StepLabel, Stepper, Switch, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext';
import { textFieldProps } from '../shared/constants';
import UploadTimeline from './UploadTimeline'
import { KeyboardTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Info } from '@material-ui/icons';
import LogbookTable from './LogbookTable';
import RLogBookTable from './RLogBookTable';
import Select from 'react-select'
import image from '../../images/TrackingOn.png'

const useStyles = makeStyles((theme) => ({  
    actionsContainer: {
      margin: '10px 0',
    },
    divider: {
        marginBottom: 10
    },
    card: {
        marginBottom: 10
    }
}));

export default function CreateLogbook() {
    const { logId } = useParams()
    const classes = useStyles()
    const { GetLogbookData, updateLogbook, getLogbook, getLogbookDataSync } = useDb()
    const logbookData = GetLogbookData(logId)
    const [logbook, setLogbook] = useState()
    const [dataFile, setDataFile] = useState()
    const [checked, setChecked] = useState(false)
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)

    const [state, setState] = useState({
        data: {
            // timelineZip: logbook && logbook.timelineZip,
            // timelineProcessed: false,
            startHours: new Date(),
            endHours: new Date(),
            businessHoursOnly: false,
            logWeekends: false,
            logPublicHolidays: false,
            step: logbook ? logbook.step : 0
        }
    })

    const getZipUrl = (u) => {
        setState({
            ...state,
            data: {
                ...state.data,
                timelineZip: u,
            }
        })
        handleUpdate()
    }

    const advanceStep = () => {
        setState({
            ...state,
            data: {
                ...state.data,
                step: state.data.step + 1
            }
        })
    }

    const handleTimeChange = (d, t, name) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: d
            }
        })
    }

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleCheck = (e) => {
        setChecked(e.target.checked)
    }

    const handleUpdate = async () => {
        await updateLogbook(logId, { ...state.data, step: state.data.step + 1 })
        advanceStep()
        firstLoad()
    }

    console.log(state.data.step)

    const firstLoad = async () => {
        const lb = await getLogbook(logId)
        const data = await getLogbookDataSync(logId)
        setState({
            ...state,
            data: {
                ...state.data,
                ...lb
            }
        })
        setDataFile(await data)
        setLogbook(await lb)
    }

    if(!logbook) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div style={{padding: 20}}>
            <Typography variant="h5">Create Logbook</Typography>
            <Stepper
                activeStep={state.data.step}
                orientation="vertical"
            >
                <Step>
                    <StepLabel>Check if google tracking is available</StepLabel>
                    <StepContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardHeader
                                        title='FML only works if you have had your Google Timeline activated during the time you need your logbook for. You can check if your Google Timeline tracking is avalible by clicking the link below. Make sure you are logged into the same Google Account as your phone.'
                                        avatar={
                                            <Avatar>
                                                <Info />
                                            </Avatar>
                                        }
                                    />
                                    <CardActions>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href="https://myactivity.google.com/activitycontrols?hl=en&gl=ZA&continue=https://timeline.google.com/maps/timeline?rapt%3DAEjHL4N6dW-K8iyVq5psqsIggEfXMt0_sPqCy_bALlWTW_cHlrJfkSqWOJ4S2bGb-rQxtTP3d8w5WJ9Anv0xCnpx9Q-IIx11vQ%26pb&settings=location&pli=1"
                                            target="_blank"
                                        >
                                            Check My Location History Setting
                                        </Button>                                        
                                    </CardActions>
                                </Card>
                                <br />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleCheck}
                                        color='primary'
                                    />
                                } label='My Location History was ON'/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img src={image} width={520} />
                            </Grid>
                        </Grid>

                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!checked}
                            onClick={handleUpdate}
                        >
                            Next
                        </Button>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Upload Timeline Zip</StepLabel>
                    <StepContent>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={5}>
                                <Card className={classes.card}>
                                    <CardHeader
                                        title="You can get your Google Timeline ZIP by clicking the link below. See the video for detailed instructions."
                                        avatar={
                                            <Avatar>
                                                <Info />
                                            </Avatar>
                                        }
                                    />
                                    <CardActions>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href="https://takeout.google.com/settings/takeout/custom/location_history"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            My Locations Timeline
                                        </Button>
                                        {/* TODO: Link the below btn to an action that emails the link to the client here */}
                                        {/* TODO: Hide the below btn from individual users, this is only for accountants */}
                                        {/* <Button
                                            variant="contained"
                                            color="primary"
                                        >
                                            Send link to client
                                        </Button> */}

                                    </CardActions>
                                </Card>
                                <UploadTimeline 
                                    logId={logId}
                                    sendUrl={getZipUrl}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <iframe width="100%" height="450" src="https://www.youtube.com/embed/Q6MonUQ--6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Grid>
                        </Grid>
                        
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Processing ZIP File</StepLabel>
                    <StepContent>
                        {
                            !logbookData ? <div>
                                <CircularProgress />
                                <Typography>We are currently processing your zip file. This might take a while, we'll email you once it's ready.</Typography>
                            </div>
                            :
                            <div className={classes.actionsContainer}>
                                <Typography>
                                    We have succesfully processed your timeline export.
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={state.data.timelineProcessed}
                                    onClick={handleUpdate}
                                >
                                    Next
                                </Button>
                            </div>
                        }
                        
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Vehicle and Business Details</StepLabel>
                    <StepContent>
                        <FormLabel>Vehicle Details</FormLabel>
                        <Divider className={classes.divider}/>
                        <Grid container spacing={3}>
                            {/* <Grid item xs={12} md={6}>
                                <TextField
                                    {...textFieldProps}
                                    label="Vehicle Make"
                                    name="make"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    {...textFieldProps}
                                    label="Vehicle Model"
                                    name="model"
                                    onChange={handleChange}
                                />
                            </Grid> */}
                            <Grid item xs={12} md={6}>
                                <TextField
                                    {...textFieldProps}
                                    label="Current ODO Reading (km's)"
                                    name="odo"
                                    onChange={handleChange}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    {...textFieldProps}
                                    label="Vehicle Book Value"
                                    name="book_value"
                                    onChange={handleChange}
                                    type="number"
                                />
                                <Button variant="contained" color="primary" href='https://carbookvalue.co.za/' target={'_blank'}>Get Vehicle Book Value</Button>
                            </Grid>
                        </Grid>

                        <FormLabel>Business Details</FormLabel>
                        <Divider className={classes.divider}/>
                        <FormControlLabel
                            control={
                            <Switch
                                checked={state.businessHoursOnly}
                                onChange={() => setState({ ...state, data: { ...state.data, businessHoursOnly: !state.data.businessHoursOnly }})}
                                name="checkedB"
                                color="primary"
                            />
                            }
                            label="Only log km's as business during working hours"
                        />

                        {
                            state.data.businessHoursOnly &&
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <>
                                            <KeyboardTimePicker
                                                label="Business Hours Start"
                                                placeholder="08:00 AM"
                                                mask="__:__ _M"
                                                value={state.data.startHours}
                                                onChange={(d,t) => handleTimeChange(d, t, 'startHours')}
                                            />
                                        </>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <>
                                            <KeyboardTimePicker
                                                label="Business Hours End"
                                                placeholder="08:00 AM"
                                                mask="__:__ _M"
                                                value={state.data.endHours}
                                                onChange={(d,t) => handleTimeChange(d, t, 'endHours')}
                                            />
                                        </>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        }
                        <FormGroup>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={state.logWeekends}
                                    onChange={() => setState({ ...state, data: { ...state.data, logWeekends: !state.data.logWeekends }})}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Log weekends as business km's"
                            />
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={state.logPublicHolidays}
                                    onChange={() => setState({ ...state, data: { ...state.data, logPublicHolidays: !state.data.logPublicHolidays }})}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Log public holidays as business km's"
                            />
                        </FormGroup>
                        
                        
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ clear: 'both' }}
                            onClick={handleUpdate}
                        >
                            Next
                        </Button>
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Preview / Edit Logbook</StepLabel>
                    {
                        state.data.step === 4 && logbookData
                        &&
                        <RLogBookTable 
                            logbook={logbook}
                            data={dataFile && dataFile}
                            odo={state.data.odo}
                            startHours={state.data.startHours}
                            endHours={state.data.endHours}
                            businessHoursOnly={state.data.businessHoursOnly}
                            logWeekends={state.data.logWeekends}
                            logPublicHolidays={state.data.logPublicHolidays}
                        />
                    }
                    <StepContent>
                       
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Export SARS-Ready Logbook</StepLabel>
                    <StepContent>
                        <Button
                            variant="contained"
                            color="primary"
                        >
                            Next
                        </Button>
                    </StepContent>
                </Step>
            </Stepper>
        </div>
    )
}
